<template>
<div class="page-content-container">
  <component :is="template" :params="params"/>
</div>
</template>
<script>
export default {
    name: 'contact',
    layout: 'page',
    data() {
       return {
          params: {}
       }
    },
   
    computed: {
      template () {
          let name = 'Contact'

          return () => import(`~/components/theme-modern-immobilier/template/Template${name}`)
      },
      structuredData() {
            var primaryImageUrl = null
            if(this.$store.state.organization.item.primaryImage) {
                primaryImageUrl = this.$store.state.organization.item.primaryImage.url
            }

            var secondaryImageUrl = null
            if(this.$store.state.organization.item.secondaryImage) {
                secondaryImageUrl = this.$store.state.organization.item.secondaryImage.url
            }

          return{ 
                "@context":"http://schema.org",
                "@graph": 
            [
               {
                    "@type": "WebPage",
                    "name": this.$store.state.pages.item.metaTitle,
                    "description": this.$store.state.pages.item.metaDescription
                },
                {
                    "@type": "LocalBusiness",
                    "image": [
                                primaryImageUrl,
                                secondaryImageUrl
                                ],
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": this.$store.state.organization.item.addresses[0].country,
                        "addressRegion": this.$store.state.organization.item.addresses[0].city,
                        "streetAddress": this.$store.state.organization.item.addresses[0].address
                    },
                    "description": this.$store.state.organization.item.description,
                    "name": this.$store.state.organization.item.name,
                    "telephone": this.$store.state.organization.item.phone,
                    "priceRange":"25$"
                }
            ]
                }
        }
    },
    async fetch({ app, params, store, payload }) {
        if (payload) {

            store.commit('pages/setItem', payload.webPage)
        } else {

            store.dispatch('pages/getOneBy', { slug: 'contact' })
        }
        store.dispatch('team/getList')
        const breadcrumb = [
              { 
                slug: '/' + store.state.i18n.currentLocale,
                name: app.i18n.t('accueil'),
                route: {
                  name: 'index'
                }
              },
              { 
                slug: app.i18n.t('contact'),
                name: app.i18n.t('Contact'),
                route: {
                  name: 'page',
                  params: {
                    page: app.$tradLinkSlug(store.state.i18n.currentLocale, 'contact', 'webPage')
                  }
                }
              }
              
              
            ]
        store.commit('pages/setBreadcrumb', breadcrumb)
    },
    head() {
        let filename = (null !== this.$store.state.pages.item.primaryImage) ? this.$store.state.pages.item.primaryImage.filename: null
        
        let metaTitle = this.$i18n.t(this.$store.state.pages.item.metaTitle) + ' | ' +this.$store.state.organization.item.name
        let metaDescription = this.$i18n.t(this.$store.state.pages.item.metaDescription)

        return {
            htmlAttrs: {
              lang: this.$store.state.i18n.currentLocale,
            },
            title:  metaTitle,
            __dangerouslyDisableSanitizers: ['script'],
            script: [{ innerHTML: JSON.stringify(this.structuredData), type: 'application/ld+json' }],
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { 
                    hid: 'description'
                    , name: 'description'
                    , content: metaDescription 
                },
                {
                  hid: `og:title`,
                  property: 'og:title',
                  content: metaTitle
                },
                {
                  hid: `og:description`,
                  property: 'og:description',
                  content: this.$store.state.pages.item.metaDescription
                },
                {
                  hid: `og:url`,
                  property: 'og:url',
                  content: process.env.URL_WEBSITE + this.$route.fullPath
                },
                {
                  hid: `og:type`,
                  property: 'og:type',
                  content: 'WebPage'
                },
                {
                  hid: `og:locale`,
                  property: 'og:locale',
                  content: this.$store.state.i18n.currentLocale
                },
                {
                  hid: `og:image`,
                  property: 'og:image',
                  content: process.env.URL_CDN + process.env.PATH_DEFAULT_MEDIA + filename
                },
                {
                  hid: `og:site_name`,
                  property: 'og:site_name',
                  content: this.$store.state.organization.item.name
                }
            ]
        }
    }
}
</script>
